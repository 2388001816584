export type AppMode = "original-app" | "new-app";
import React from "react";

export const getAppMode = (): AppMode => {
    // @ts-ignore
    return __APP_MODE__;
};

export const renderByAppMode = (original: () => any, neu: () => any): any => (getAppMode() === "new-app" ? neu() : original());
export const byAppMode = (original: React.ComponentType, neu: React.ComponentType): React.ReactNode => {
    const Comp = getAppMode() === "new-app" ? neu : original;

    return <Comp />;
};

export const NotInNewApp: React.ComponentType<{ children: JSX.Element[] | JSX.Element }> = ({ children }) =>
    renderByAppMode(
        () => children,
        () => null
    );
